<template>
  <v-form ref="registerForm" @submit.prevent="register">
    <v-text-field
      solo
      v-model="user.name"
      :rules="[rules.required]"
      :label="$t('common.name')"
      prepend-inner-icon="mdi-account"
    ></v-text-field>
    <v-text-field
      solo
      v-model="user.email"
      :rules="[rules.required, rules.email]"
      :label="$t('common.email')"
      prepend-inner-icon="mdi-email"
    ></v-text-field>
    <v-text-field
      solo
      v-model="user.password"
      :rules="[rules.required, rules.passwordMinLength]"
      type="password"
      :label="$t('common.password')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>
    <v-text-field
      solo
      v-model="user.confirmPassword"
      :rules="[rules.required, rules.equalWithPassword(user.password)]"
      type="password"
      :label="$t('common.confirmPassword')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>

    <p>
      {{ $t('termsAndConditions.formText') }}
      <router-link target="_blank" :to="'terms-and-conditions'">
        <span>{{ $t('termsAndConditions.title') }} </span>
      </router-link>
      <span> {{ $t('termsAndConditions.and') }} </span>
      <router-link target="_blank" :to="'privacy-policy'">
        <span>{{ $t('privacyPolicy.title') }} </span>
      </router-link>
    </p>

    <v-card-actions class="pa-0">
      <v-btn dark block color="primary" type="submit" :loading="loading">
        {{ $t('auth.signUp') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import validators from '../../common/validators';
import { REGISTER } from '../../store/auth/types';

const PASSWORD_MIN_LENGTH = 6;

export default {
  name: 'register-form',
  data() {
    return {
      loading: false,
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        email: validators.isEmail(this.$i18n.t('common.invalidEmail')),
        passwordMinLength: validators.min(
          PASSWORD_MIN_LENGTH,
          this.$i18n.t('common.minLength', {
            value: PASSWORD_MIN_LENGTH
          })
        ),
        equalWithPassword: prop =>
          validators.equal(prop, this.$i18n.t('common.passwordMismatch'))
      }
    };
  },
  methods: {
    async register() {
      if (!this.$refs.registerForm.validate()) {
        return;
      }

      this.loading = true;

      try {
        const result = await this.$store.dispatch(REGISTER, this.user);
        this.$i18n.locale = result.lang;

        this.$router.push({
          path: '/successfully-registration',
          query: {
            email: this.user.email
          }
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
