<template>
  <v-card class="register-form">
    <v-container class="pa-6">
      <h4 class="text-h5 text-center mb-6 font-weight-light text-none">
        {{ $t('auth.signUpForFree') }}
      </h4>
      <social-auth authType="signup"></social-auth>
      <h4 class="text-h6 text-center my-3 font-weight-light text-uppercase">
        {{ $t('common.or') }}
      </h4>
      <v-btn
        block
        outlined
        color="app-blue"
        dark
        class="mb-4"
        @click="toggle()"
      >
        {{ $t('auth.gamRegister') }}
        <v-icon right dark v-if="!gamRegister">mdi-chevron-down</v-icon>
        <v-icon right dark v-else>mdi-chevron-up</v-icon>
      </v-btn>
      <register-form v-if="gamRegister"></register-form>
      <div class="text-center mt-3">
        <span>{{ $t('auth.youHaveAnAccount') }}</span>
        <router-link :to="'login'" color="app-blue">
          {{ $t('auth.login') }}
        </router-link>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import SocialAuth from './SocialAuth';
import RegisterForm from './RegisterForm';

export default {
  name: 'register-panel',
  components: {
    SocialAuth,
    RegisterForm
  },
  data() {
    return {
      gamRegister: false
    };
  },
  methods: {
    toggle() {
      this.gamRegister = !this.gamRegister;
    }
  }
};
</script>
