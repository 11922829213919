<template>
  <v-container class="pa-6">
    <v-row justify="space-around">
      <v-col lg="7" md="6" cols="12">
        <v-responsive class="hidden-xs-only hidden-sm-only">
          <v-img
            max-height="500"
            contain
            class="border-radius-10"
            :src="images.heroes"
          ></v-img>
        </v-responsive>
      </v-col>
      <v-col lg="5" md="6" cols="12">
        <register-panel></register-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegisterPanel from '../components/auth/RegisterPanel';

export default {
  name: 'sign-up',
  components: {
    RegisterPanel
  },
  data() {
    return {
      images: {
        heroes: require('../assets/images/gamifinnator-gamifinna.png')
      }
    };
  }
};
</script>
